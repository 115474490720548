.keyboard-button
  user-select: none
  -moz-user-select: none
  -webkit-user-select: none
  position: relative
  display: inline-block
  cursor: pointer
  margin-bottom: 0.5em
  outline: none
  vertical-align: middle
  text-decoration: none
  font-weight: 600
  color: #382b22
  text-transform: capitalize
  background: #f3f3f3
  border: 2px solid #8c8c8c
  border-radius: 0.75em
  transform-style: preserve-3d
  transition: transform 150ms cubic-bezier(0, 0, 0.58, 1), background 150ms cubic-bezier(0, 0, 0.58, 1)
  &:before
    position: absolute
    content: ''
    width: 100%
    height: 100%
    top: 0
    left: 0
    right: 0
    bottom: 0
    background: #cbcbcb
    border-radius: inherit
    box-shadow: 0 0 2px 2px #8c8c8c
    transform: translate3d(0, 0.4em, -0.6em)
    transition: transform 150ms cubic-bezier(0, 0, 0.58, 1), box-shadow 150ms cubic-bezier(0, 0, 0.58, 1)
  &:hover
    background: #f3f3f3
    transform: translate(0, 0.2em)
  &:hover::before
    box-shadow: 0 0 0 2px #8c8c8c
    transform: translate3d(0, 0.25em, -1em)
  &:active
    background: #f3f3f3
    transform: translate(0em, 0.5em)
  &:active::before
    box-shadow: 0 0 0 2px #8c8c8c
    transform: translate3d(0, 0, -0.5em)
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.react-flow__handle{
  height: 11px !important;
  width: 11px !important;
}

tr.drop-over-downward td {
  border-bottom: 2px dashed #1890ff !important;;
}

tr.drop-over-upward td {
  border-top: 2px dashed #1890ff
}

/*TODO where do we put this ??? _logo.less ??*/
.logo img {
  max-height: 100%;
  object-fit: contain;
}
